import { createRouter as _createRouter, createWebHistory } from "vue-router";

import {
  USER_EXIST,
  CREATE,
  VERIFICATION,
  PASSPORT,
  ADDITIONAL,
  CARD,
  AGREEMENTS,
  WAITING_RESULT,
  PRIOR_APPROVAL,
  CHECK_CODE_AGREEMENT,
  APPROVED,
  DECLINED,
  NEEDPHOTO,
  REPEATER,
  PROLONGATION_WAITING,
  PROLONGATION_PRIOR,
  PROLONGATION_CHECK_CODE,
} from "@/router/routes";

import routeMap from "@/router/status-to-route";

import LayoutForm from "@/layouts/layout-form/layout-form.vue";

const routes = (beforeRouteMiddleware) => [
  {
    path: "/",
    component: LayoutForm,
    beforeEnter: beforeRouteMiddleware,
    children: [
      {
        path: CREATE.path,
        name: CREATE.name,
        meta: {
          title: "Общие сведения",
          back: false,
        },
        component: () =>
          import("@/views/primary-form/1-1.create-new/create.vue"),
      },
      {
        path: VERIFICATION.path,
        name: VERIFICATION.name,
        meta: {
          title: "Подтверждение номера",
        },
        component: () =>
          import(
            "@/views/primary-form/1-2.sms-verification/sms_verification.vue"
          ),
      },
      {
        path: PASSPORT.path,
        name: PASSPORT.name,
        meta: {
          title: "Паспортные данные",
        },
        //component: () => import("@/views/primary-form/2.passport/Passport"),
        component: () =>
          import("@/views/primary-form/2.passport-new/passport-new.vue"),
      },
      {
        path: ADDITIONAL.path,
        name: ADDITIONAL.name,
        meta: {
          title: "Дополнительная информация",
        },
        component: () =>
          import("@/views/primary-form/3.additional/additional.vue"),
      },
      {
        path: CARD.path,
        name: CARD.name,
        meta: {
          back: false,
          title: "Данные вашей карты",
        },
        component: () => import("@/views/primary-form/4.card/card.vue"),
      },
      {
        path: WAITING_RESULT.path,
        name: WAITING_RESULT.name,
        meta: {
          title: "Проверка данных",
          back: false,
        },
        component: () =>
          import("@/views/primary-form/5-1.waiting-result/waiting-result.vue"),
      },
      {
        path: DECLINED.path,
        name: DECLINED.name,
        meta: {
          back: false,
        },
        component: () =>
          import("@/views/primary-form/5-5.declined/declined.vue"),
      },
      {
        path: PRIOR_APPROVAL.path,
        name: PRIOR_APPROVAL.name,
        meta: {
          title: "Предварительное одобрение",
          back: false,
        },
        component: () =>
          import("@/views/primary-form/6-1.prior-approval/prior-approval.vue"),
      },
      {
        path: CHECK_CODE_AGREEMENT.path,
        name: CHECK_CODE_AGREEMENT.name,
        meta: {
          title: "Подтверждение",
          back: false,
        },
        component: () =>
          import(
            "@/views/primary-form/6-5.check-code-agreement/check-code-agreement.vue"
          ),
      },
      {
        path: NEEDPHOTO.path,
        name: NEEDPHOTO.name,
        meta: {
          title: "Подтверждение личности",
          back: false,
        },
        component: () => import("@/components/need-photo/need-photo.vue"),
      },
      {
        path: APPROVED.path,
        name: APPROVED.name,
        meta: {
          title: "Одобренный",
          back: false,
        },
        component: () => import("@/views/primary-form/7.approved/approved.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/window/window.vue"),
    children: [
      {
        path: USER_EXIST.path,
        name: USER_EXIST.name,
        component: () => import("@/views/window/user-exist/user_exist.vue"),
      },
      {
        path: AGREEMENTS.path,
        name: AGREEMENTS.name,
        component: () => import("@/views/window/agreements/agreements.vue"),
      },
      {
        path: "/esia/after",
        name: "esiaCode",
        component: () => import("@/views/esia/after.vue"),
      },
    ],
  },
  {
    path: "/",
    beforeEnter: beforeRouteMiddleware,
    component: () => import("@/layouts/layout-default/layout-default.vue"),
    children: [
      {
        path: REPEATER.path,
        name: REPEATER.name,
        meta: {
          title: "Одобренный",
          back: false,
        },
        component: () => import("@/views/repeater/1.main/main.vue"),
      },
    ],
  },
  {
    path: "/",
    beforeEnter: beforeRouteMiddleware,
    component: () => import("@/layouts/layout-default/layout-default.vue"),
    children: [
      {
        path: PROLONGATION_WAITING.path,
        name: PROLONGATION_WAITING.name,
        component: () =>
          import("@/views/refin/1.waiting-result/waiting-result.vue"),
      },
      {
        path: PROLONGATION_PRIOR.path,
        name: PROLONGATION_PRIOR.name,
        component: () =>
          import("@/views/refin/2.prior-approval/prior-approval.vue"),
      },
      {
        path: PROLONGATION_CHECK_CODE.path,
        name: PROLONGATION_CHECK_CODE.name,
        component: () =>
          import(
            "@/views/refin/3.check-code-agreement/check-code-agreement.vue"
          ),
      },
    ],
  },
  {
    path: "/showcases",
    name: "showcases",
    component: () => import("@/views/showcases/showcases.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import("@/layouts/layout-default/layout-default.vue"),
    children: [
      {
        path: "/:pathMatch(.*)*",
        name: "404",
        component: () =>
          import(
            /* webpackChunkName: "contact-view" */
            "@/views/404/404.vue"
          ),
      },
    ],
  },
];

export function createRouter(initObj) {
  const beforeRouteMiddleware = async (to, from, next) => {
    console.log("store : ", initObj);
    if (!initObj.store.state.application.init) {
      await initObj.store.dispatch("application/getStatus");
    }

    routeMap.getName(initObj.store, (name) => {
      next(to.name === name ? null : { name });
    });
  };

  return _createRouter({
    history: createWebHistory(),
    scrollBehavior: () => ({ top: 0 }),
    // mode: "history",
    // base: process.env.BASE_URL,
    routes: routes(beforeRouteMiddleware),
  });
}
