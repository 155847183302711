export const USER_EXIST = {
  name: "UserExist",
  path: "/user-exist",
};

export const CREATE = {
  name: "Create",
  path: "/",
  step: 1,
};

export const VERIFICATION = {
  name: "Verification",
  path: "/verification",
  step: 1.5,
};

export const PASSPORT = {
  name: "Passport",
  path: "/passport",
  step: 2,
};

export const ADDITIONAL = {
  name: "Additional",
  path: "/additional",
  step: 3,
};

export const CARD = {
  name: "Card",
  path: "/card",
  step: 4,
};

export const WAITING_RESULT = {
  name: "WaitingResult",
  path: "/waiting-result",
  step: 4,
};

export const DECLINED = {
  name: "Declined",
  path: "/declined",
  step: 4.5,
};

export const AGREEMENTS = {
  name: "Agreements",
  path: "/agreements",
};

export const PRIOR_APPROVAL = {
  name: "PriorApproval",
  path: "/prior-approval",
  step: 5,
};

export const CHECK_CODE_AGREEMENT = {
  name: "CheckCodeAgreement",
  path: "/check-code-agreement",
  step: 5.5,
};

export const NEEDPHOTO = {
  name: "NeedPhoto",
  path: "/need-photo",
  step: 6.5,
};

export const APPROVED = {
  name: "Approved",
  path: "/approved",
  step: 6,
};

export const REPEATER = {
  name: "Repeater",
  path: "/repeater",
  step: 1,
};

export const PROLONGATION_WAITING = {
  name: "ProlongationWaiting",
  path: "/prolongation-waiting",
};

export const PROLONGATION_PRIOR = {
  name: "ProlongationPrior",
  path: "/prolongation-prior",
};

export const PROLONGATION_CHECK_CODE = {
  name: "ProlongationCheckCode",
  path: "/prolongation-check-code",
};
