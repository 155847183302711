import mainInit from "./main";

import infraLayer from "@frontend/allspark/infra/index_beriberu-anketa";
import UiKit from "@frontend/allspark/uikit/index_krediska";
import i18nMessages from "@/i18n/krediska";

mainInit({
  infraLayer,
  UiKit,
  i18nMessages,
  swName: "service-worker-krediska.js",
});
